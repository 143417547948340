
.gradient_line {
  min-height: 3.5px;
  border-radius: 1em;
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
}

.black_line {
  min-height: 3.5px;
  border-radius: 1em;
  background-color: #374A55;
}

.partner-img-card1 {
  border-radius: 1em 1em 0 0 ;
  background-image: url("../../assets/images//tabs/ads1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 17em;
  width:100%;
  opacity: 1;
}
.partner-img-card1:hover {
  opacity: 1;
}
.partner-img-card2 {
  border-radius: 1em 1em 0 0 ;
  background-image: url("../../assets/images//tabs/ads2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 17em;
  width:100%;
  opacity: 1;
}
.partner-img-card2:hover{
  opacity: 1;
}
.partner-img-card3 {
  border-radius: 1em 1em 0 0 ;
  background-image: url("../../assets/images//tabs/ads3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 17em;
  width:100%;
  opacity: 1;
}
.partner-img-card3:hover {
  opacity: 1;
}
.partner-img-card4 {
  border-radius: 1em 1em 0 0 ;
  background-image: url("../../assets/images//tabs/ads4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 17em;
  width:100%;
  opacity: 1;
}
.partner-img-card4:hover {
  opacity: 1;
}
.partner-brand-section {
  border-radius: 1em;
  background-image: linear-gradient(
    to left,
    #a1d0f0,
    #9ec6f2,
    #a1baf0,
    #abadec,
    #b89fe2
  );
  box-shadow: 10px 10px 35px 0px #00000024;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;
}
.partner-event-section {
  border-radius: 1em;
  background-image: linear-gradient(
    to left,
    #a1d0f0,
    #9ec6f2,
    #a1baf0,
    #abadec,
    #b89fe2
  );
  box-shadow: 10px 10px 35px 0px #00000024;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;
}
.partner-education-section {
  border-radius: 1em;
  background-image: linear-gradient(
    to left,
    #a1d0f0,
    #9ec6f2,
    #a1baf0,
    #abadec,
    #b89fe2
  );
  box-shadow: 10px 10px 35px 0px #00000024;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;
}
.partner-img-heading{
  background: #78ceff;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text {
  background: linear-gradient(
    90deg,
    #ca3277 0%,
    #a81aca 35.42%,
    #6928ca 67.19%,
    #0f42bb 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.schedule_demo-btn {
  border-radius: 0.5em;
  padding: 0.8em 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  outline: none !important;
  background-color: #0061fc;
  margin: 1.5em 0 0 0;
  color: white;
  cursor: pointer;
}
.cols-radius{
  border-radius: 1em;
  overflow: hidden;
  background-color:white;
  /* padding: 2.5em 0 0 0 ; */
  margin: 0;
}


@media(min-width:1500px) and (max-width:2000px){
  .partner-img-card1 {
    height: 20em;
  }
  .partner-img-card2 {
    height: 20em;
  }
  .partner-img-card3 {
    height: 20em;
  }
  .partner-img-card4 {
    height: 20em;
  }
}
@media(min-width:2001px) and (max-width:2600px){
  .partner-img-card1 {
    height: 22em;
  }
  .partner-img-card2 {
    height: 22em;
  }
  .partner-img-card3 {
    height: 22em;
  }
  .partner-img-card4 {
    height: 22em;
  }
}