.video-container::after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.video-container {
  height: 650px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
/* #myVideo {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
} */
.callout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80%;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
  text-align: left;
}
.callout h2 {
  font-weight: 600;
  color: #fff;
  font-size: 46px;
}

.callout p {
  color: #fff;
  font-size: 20px;
}

.callout a {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff !important;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  gap: 12px;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 10px;
  font-style: normal;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  width: 180px;
  cursor: pointer;
}
.homevid{
  margin-top: 5%;
}
@media(max-width:600px){
  .homevid{
    margin-top: 17%;
  }
}