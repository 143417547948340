.prerelease-container {
  background-image: url("../../assets/images/prerelease_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 9%;
}
.press-para {
  font-size: 14px !important;
}
.underline {
  background-color: black;
  height: 3.5px;
  width: 75px;
  border-radius: 0.5em;
}
.carousal-card {
  background-image: url("../../assets/images/card_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2px 20px;
  width: 75%;
  position: relative;
}
.effect-left-bottom {
  position: absolute;
  left: -15%;
  bottom: -45%;
}
.effect-right-top {
  position: absolute;
  top: -45%;
  right: -15%;
}
.icon_box {
  background-color: white;
  border-radius: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  width: 4em;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}
.empty {
  height: 4em;
  width: 4em;
}
.press p {
  background: #0744b9;
  background: linear-gradient(
    to right,
    #0744b9 0%,
    #9d19d3 50%,
    #dd4049 100%
  ) !important;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 14px;

  display: inline-block;
  text-align: center;
}

.avatar-style {
  height: 150px;
  width: 120px;
}

.press-card{
  width:100%;
  position: relative;
}
.press_logo-section{
  position: absolute;
  top:-5%;
  left:32%;
  right:32%;
  background-color: white;
  padding: 0 1em;
  border-radius: .5em;
}
/* .press-card:hover{
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
} */
.press-img{
  height: 15em;
  width: 100%;
  object-fit: cover;
  border-radius: .2em;
}

@media (max-width: 600px) {
  .prerelease-container {
    padding: 5%;
  }
  .carousal-card {
    background-image: url("../../assets/images/card_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 2px 20px;
    width: 95%;
    position: relative;
  }
  .press-mobile p {
    font-size: 12px;
  }
  .avatar-style {
    height: 100px;
    width: 90px;
  }
  .empty {
    height: 2em;
    width: 2em;
  }
  .icon_box {
    height: 2em;
    width: 2em;
  }
  .section-title.text-center.teamheading h4 {
   font-size: 30px;
   }
   .press-img{
    height: 10em;
    width: 100%;
    object-fit: cover;
    border-radius: .2em;
  }
}
