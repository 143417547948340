.header_container {
  font-family: "LufgaRegular" !important;
  background-color: white !important;
  z-index: 99;
  align-items: "center";
  box-shadow: 0px 8px 18px rgba(20, 18, 103, 0.03), 0px 2px 20px rgba(23, 58, 110, 0.03);
}
.nav-link.nav-link {
  color: black !important;
  font-size: 16px !important;
  font-weight: 600;
}
.navbar-nav {
  --bs-nav-link-padding-x: 0 !important;
  --bs-nav-link-padding-y: 0 !important;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  margin: 0 0 0 5% !important;
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  padding-left: 0;
  list-style: none;
  width: 100% !important;
}

.button-file {
  position: relative;
  overflow: hidden;
  z-index: 0;
  text-decoration: none;
  padding: .5em 1em;
  cursor: pointer;
  font-weight: 500;
  background-color: transparent !important;
  border: none !important;
  width:220px;
  font-size: 17px !important;
}
.button-file i {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.button-file i::before {
  content: "";
  position: absolute;
  left: 20%;
  top: 50%;
  /* width: 350px;
  height: 240px; */
  transform: translate(-50%, -50%) rotate(45deg);
  transition: transform 0.7s linear;
  background: #eb4a26;
  background: linear-gradient(
    to right,
    rgb(235 74 38 / 44%) 40%,
    rgb(164 23 212 / 44%) 50%,
    rgb(0 70 184 / 44%) 62%
  );
}

.button-file:hover i::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.navbar-avatar{
  height: 2.5em;
  width: 2.5em;
  border-radius: 1.25em;
  overflow: hidden;
  background-color: lightgray;
}

.primary-button{
  background: linear-gradient(102.66deg, #78CEFF -17.72%, #0295FA 80.89%);
  padding: .6em 2em;
  border-radius: 1.5em;
  border: none !important;
  outline: none !important;
  color: white;
  box-shadow:0px, 4px
  rgba(0, 0, 0, 0.25) ;
  font-weight: bold;
}
@media (max-width: 600px) {
  .navbar-nav {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
  .nav-link {
    margin: 2% 0 !important;
  }
  .mobile-down{
    flex-direction: column;
  }
}
