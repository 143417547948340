body {
  /* overflow: hidden; */
  margin: 0;
  padding: 0;
  font-family: "LufgaRegular" !important;
  word-wrap: break-word;
}
.App {
  max-height: 100%;
  overflow: hidden;
  /* font-size: calc(15px + 0.390625vw); */
}

.lufga {
  font-family: "LufgaRegular";
}
.lufga-bold {
  font-family: "LufgaBold";
}

::-webkit-scrollbar {
  display: none;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary-square-button {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  padding: 0.6em 2em;
  border-radius: 0.5em;
  border: none !important;
  outline: none !important;
  color: white;
  box-shadow: 0px, 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-button {
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
  padding: 0.6em 2em;
  border-radius: 1.5em;
  border: none !important;
  outline: none !important;
  color: white;
  box-shadow: 0px, 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.metablog_gradient-button {
  background: linear-gradient(white 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(-90deg, #eb4a26 0%, #a417d4 45.31%, #0046b8 100%)
      border-box;
  border: 1px solid transparent;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-button{
  background-color: transparent;
  padding: 0.6em 2em;
  border-radius: 1.5em;
  border: 1px solid black !important;
  outline: none !important;
  color: white;
  box-shadow: 0px, 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-modal{
  background-color: transparent;
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*===========Landing Page CSS Start Here=============*/

header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0px;
  padding: 10px 0;
  z-index: 99;
}
header .container {
  /*  justify-content: space-between; display: flex;*/
  align-items: center;
}

.registerheader.sticky {
  background: rgba(255, 255, 255, 0.99);
}

.whitelogo {
  display: block;
}

.blacklogo {
  display: none;
}

.registerheader.sticky .main-navigation ul li a {
  color: #000;
}

.registerheader.sticky .button-file {
  color: #000;
}
.brand-logo img,
.foot-logo img {
  width: 170px;
}

.registerheader.sticky .blacklogo {
  display: block;
}

.registerheader.sticky .whitelogo {
  display: none;
}

.logingraytop {
  height: 90vh;
  position: relative;
  background-image: url("./assets/images/hero-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5%;
}

.inner-login {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
}

.logingrayinn {
  display: block;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  margin: auto;
  border-radius: 20px;
  padding: 1em 2em; /* display: flex; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logingrayinnblur select {
  background-color: transparent;
  border: 0px;

  font-style: normal;
  font-size: 18px;
  text-align: right;
  color: #fff;
}

.logingrayinnblur select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: inherit;
}

.logingrayinnblur h2 {
  font-style: normal;
  font-size: 30px;
  color: #fff;
  margin: 0 0 30px 0;
}

.loginsocial {
  padding: 0 0 0px 0;
  margin: 0 0 20px 0;
  list-style: none;
  display: flex;
  width: 100%;
  text-align: center;
}
.loginsocial li a img {
  width: 90%;
}

.forgot {
  font-style: normal;
  font-size: 16px;
  background: radial-gradient(
    136.03% 6755.5% at 111.63% -36.03%,
    #eb4a26 0%,
    #a417d4 45.31%,
    #0046b8 100%
  );
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  display: inline-block;
  margin: 10px 0;
  text-decoration: none;
}

.logingrayinn i::before {
  width: 115% !important;
}

.logingrayinnblur {
  z-index: 999;
  position: relative;
}

.universebtn {
  margin: 40px 0;
}

.or::after {
  content: "";
  display: block;
  width: 3%;
  height: 2px;
  background: #fff;
  position: absolute;
  margin-top: -11px;
  z-index: 0;
  right: 43%;
  top: 24px;
}

.or {
  margin: 30px 0;
  position: relative;
  text-align: center;
  color: #fff;
  font-style: normal;

  font-size: 18px;
}

.or::before {
  content: "";
  display: block;
  width: 3%;
  height: 2px;
  background: #fff;
  position: absolute;
  margin-top: -11px;
  z-index: 0;
  left: 43%;
  top: 24px;
}

.loginformmain {
  margin: 0 auto;
  width: 80%;
  z-index: 999;
  position: relative;
}

.logingray {
  width: 100%;
  position: relative;
}

.loginformmain input {
  width: 100%;
  margin: 0 0px 20px 0 !important;
  display: block;
  background: #fff;
  height: 40px;
  border: 0px;

  font-style: normal;
  font-size: 15px;
  line-height: 23px;
  color: #caced8;
}

.form-reg {
  text-align: right;
}

#loginbtnn {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 10px;
  border: 0px;
  display: block;
  width: 100%;
  padding: 8px 0;
  font-style: normal;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #fff;
}
#connectbtnn{
  background-color: transparent !important;
  border-radius: 10px !important;
  border: 1px solid black !important;
  width: 100%;
  padding: 8px 0;
  font-style: normal;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #fff;
}

.logingrayinnblur select option {
  color: black;
}

.main-navigation ul {
  display: flex;
  margin: 0;
}

.button-file.btn-login i::before {
  display: none !important;
}

.button-file.btn-login {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  text-decoration: none;
  color: #fff !important;
}

#myVideo {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

#myVideo1 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.video-container::after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.video-container {
  height: 650px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.callout {
  position: relative;
  display: flex;
  justify-content: center;

  flex-direction: column;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
  text-align: left;
}

.callout h2 {
  color: #fff;
  font-size: 46px;
}

.callout p {
  color: #fff;
  font-size: 14px;
}

.callout a {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff !important;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  gap: 12px;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 10px;

  font-style: normal;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  width: 180px;
}

.callout.explorelast {
  text-align: center;
  display: inline-flex;
  margin: 0 auto;
  width: 100%;
  flex-direction: inherit;
}

.ecoinn {
  margin: 0px 0 0 0;
}

.main-navigation ul li {
  padding: 0 15px;
  list-style: none;
}

.main-navigation ul li a {
  display: block;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  padding: 10px 5px;
}

.metalivingleft {
  text-align: left;
  position: relative;
}

.newbenifit {
  display: none;
}

.metalivingright.showcube {
  display: none;
}

.topheader {
  width: 100%;
  display: block;
}

.topheaderone {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-right .joinbtntop {
  font-size: 18px;
  color: #fff;
  height: 43px;
  line-height: 44px;
  padding: 0 15px;
  background-color: transparent;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;

  border-image-source: radial-gradient(
    98.46% 98.46% at 50.75% 1.54%,
    rgba(235, 74, 38, 0.38) 0%,
    rgba(164, 23, 212, 0.38) 40.82%,
    rgba(0, 70, 184, 0.38) 100%
  );
  border-radius: 22px;
}

.button-file {
  position: relative;
  overflow: hidden;
  z-index: 0;
  text-decoration: none;
  padding: 11px 40px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 18px;
}

.button-file i {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.button-file i::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 240px;
  transform: translate(-50%, -50%) rotate(95deg);
  transition: transform 0.7s linear;
  background: #eb4a26;
  background: linear-gradient(
    to right,
    rgb(235 74 38 / 44%) 40%,
    rgb(164 23 212 / 44%) 50%,
    rgb(0 70 184 / 44%) 62%
  );
}

.button-file:hover i::before {
  transform: translate(-50%, -50%) rotate(315deg);
}

.metaliving {
  margin: 80px 0 0;
  background-color: #fff;
  box-shadow: 10px 10px 35px 0px #00000024;
  border-radius: 33px;
  padding: 50px;
}

.frameone {
  position: absolute;
  width: 100%;
  z-index: -2;
}

.cta-container .row {
  position: relative;
  z-index: 1;
}

.pyramidmain {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  bottom: -167px;
  z-index: -1;
}

.citizensmain img {
  /*width: 60%;*/
  width: 80%;
  margin-left: -10%;
}

.pyramidright img {
  width: 60%;
  float: right;
}

.cta-text {
  padding-top: 50px;
  padding-bottom: 120px;
  padding-left: 0px;
  text-align: center;
}
.cta-text h4 {
  font-size: 42px;
  line-height: 42px;
  color: #000;
  padding-bottom: 60px;
  max-width: 292px;
}

.cta-text h3 {
  font-size: 64px;
  line-height: 64px;
  color: #020202;
  padding-bottom: 30px;
}

.cta-text h3 {
  color: #020202;
  padding-bottom: 10px;
  font-size: 55px;
}

.cta-text span {
  display: block;
  color: #020202;
  padding-bottom: 20px;
  font-size: 32px;
}

.secsection {
  background-image: url("./assets/images/possiblities-bg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.metalivingright {
  -webkit-animation: swing 1s ease-in-out infinite alternate;
  animation: swing 1s ease-in-out infinite alternate;
  -webkit-transform-origin: center -20px;
  transform-origin: center -20px;
  float: left;
  width: 100%;
  position: relative;
}

.metalivingright img {
  width: 310px;
  position: absolute;
  top: -90px;
  left: -20px;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}
@keyframes swing {
  0% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}

.metalivingleft h2 {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 44px;
  margin: 0px 0 10px 0;
  display: inline-block;
}

.metalivingleft h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  color: #1b1b1b;
}

.metalivingleft span {
  font-size: 18px;
  margin: 0 0 15px 0;
  color: #1b1b1b;
  display: block;
}

.button.button-blue {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  color: #fff;
  display: block;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 22px;
  transition: all 0.5s ease;
  padding: 10px 35px 10px;
  border: 1px solid transparent;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;
}

.metalivingleft h2 {
  display: block;
}

.reallife {
  background-image: url("./assets/images/realife-bg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.reallifeinn {
  padding: 7% 0;
  width: 100%;
  display: block;
}

.reallifeinn img {
  width: 100%;
  height: 100%;
  margin: 50px 0 0 0;
}

.reallifeinn h2 {
  font-weight: bold;
  font-style: normal;
  font-size: 48px;
  color: #0b213e;
}

.reallifeinn h3 span {
  background: #a317d4;
  background: linear-gradient(to right, #a317d4 0%, #d63b58 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reallifeinn h4 {
  font-weight: bold;
  font-style: normal;
  font-size: 48px;
  text-align: right;
  background: #64c3fd;
  background: linear-gradient(to right, #64c3fd 0%, #2fabfc 50%, #0295fa 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reallifeinn h3 {
  font-weight: bold;
  font-style: normal;
  font-size: 48px;
  color: #0b213e;
  text-align: center;
}

.realthing {
  background-image: linear-gradient(
    to right,
    #a1d0f0,
    #9ec6f2,
    #a1baf0,
    #abadec,
    #b89fe2
  );
  box-shadow: 10px 10px 35px 0px #00000024;
  border-radius: 33px;
  padding: 50px;
  width: 100%;
  margin: 7% 0;
}

.reallefesec h1 {
  font-weight: bold;
  font-style: normal;
  font-size: 48px;
  color: #0b213e;
  text-align: center;
  margin: 0 0 0 0;
}

.realthingright h2 {
  font-style: normal;

  font-size: 44px;
  color: #fff;
}

.realthingright span {
  font-size: 15px;
  display: block;
  color: #fff;
}

.realthingright a {
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
  color: #fff;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 100px;
  transition: all 0.5s ease;
  padding: 15px 35px 15px;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;
  text-decoration: none;
  margin: 30px 0 0 0;
}

.realthingleft {
  /*  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(27, 27, 27, 0.25);
  border-radius: 10px; */
  width: 100%;
  height: 100%;
  padding: 5px;
}

.realthingleft img {
  width: 100%;
}

.banefits-box-type-1 {
  height: 400px;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.banefits-box.banefits-box-type-1 .box-text {
  position: absolute;
  top: 40px;
  text-align: left;
}

.banefits-box.banefits-box-type-2a {
  background: #fff;
  width: 100%;
  height: 200px;
  margin: 20px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.banefits-box.banefits-box-type-3a {
  background: #fff;
  width: 100%;
  height: 300px;
  margin: 0px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.banefits-box-type-2 {
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.banefits-box-type-3 {
  height: 260px;
}

.banefits-box-type-4 .opone {
  margin: 0px !important;
}

.banefits-box h4 {
  font-size: 22px;
  line-height: 42px;
  color: #fff;
  padding-bottom: 10px;
  text-align: left;
}

.banefits-box h2 {
  font-size: 42px;
  color: #fff;
  /* font-family: 'Lufga-Bold';*/
  line-height: auto;
  padding-bottom: 15px;
}

.banefits-box strong {
  font-family: "Lufga-Bold";
}

.banefits-box h3 {
  font-size: 26x;
  line-height: 45px;
  color: #fff;
  font-family: "Lufga-Bold";
  text-align: left;
}

.banefits-box h2 span {
  font-size: 64px;
}
/*.banefits .container{
    max-width: 1544px;
}*/

.left-arrow,
.right-arrow {
  display: inline-block;
  width: 40px;
  margin: 10px 0 0 0;
}

/*
.left-arrow.toparrow {
  right:0px;
  bottom: -50px;
  position: absolute;
}*/

.banefits-box-type-4a {
  height: 410px;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.banefits-box-type-4b {
  height: 190px !important;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.banefits-box.banefits-box-type-2.banefits-box-type-5a.mb-4 {
  height: 180px;
}

.banefits-box.banefits-box-type-2.banefits-box-type-6a.banefits-box-type-4.mb-4 {
  height: 280px;
}

.banefits-box-type-4b {
  height: 400px;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.banefits-box.banefits-box-type-3d {
  background: #fff;
  background-size: auto;
  width: 100%;
  height: 296px;
  margin: 0px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.gallery-item.gallerboxleftbg .gallery-text {
  border-radius: 0 70px 70px 20px;
}

.banefits-box-type-4 h4 {
  position: inherit;
}

.banefits-box-type-4 div {
  margin: 30px 0 0 0 !important;
}

.box-text.lastbanefitsup {
  position: inherit;
  margin: 140px 0 0 0;
  padding-bottom: 0;
}

.left-arrow.side {
  margin: 0px 0 0 0px;
  position: absolute;
  left: 0;
  bottom: -50px;
}

.lastbanefitsinn h3 {
  text-align: right;
}

.banefits-box-type-2 .box-text {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

.nav.banefitsmain li {
  margin: 0 38px 0 0;
  width: 31%;
}

.nav.banefitsmain li:last-child {
  margin: 0;
}

.opone p {
  font-size: 18px;
  display: block;
  color: #000;
  opacity: 1;
  z-index: 999;
  position: relative;
  padding: 0 30px;
  text-align: justify;
}

/* .banefits-box-type-4a::after {
  content: "";
  background: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
} 



.banefits-box-type-4b::after {
  content: "";
  background: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.banefits-box-type-6a::after {
  content: "";
  background: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
*/

.banefits-box.banefits-box-type-2.lastbanefits {
  height: 250px;
}

.banefits-box-type-6a {
  height: 330px !important;
}

.nav.nav-pills.banefitsmain {
  margin: 0 0 15px 0;
}

.nav.banefitsmain .nav-link h2 {
  font-style: normal;
  font-size: 22px;
  /* border-bottom: 4px solid #6f7272; */
  margin: 0 0 15px 0;
  padding: 0 0 15px 0;
  position: relative;
}

.nav.banefitsmain .nav-link.active p {
  color: #67808e;
}

.nav.banefitsmain .nav-link p {
  font-style: normal;

  font-size: 16px;
  color: #9daeb7;
  text-align: left;
}

.nav.banefitsmain .nav-link.active {
  background-color: transparent !important;
}

.nav.banefitsmain .nav-link {
  padding: 0px;
  width: 100%;
}

/* DEMO 2 ============================== */
.hover-2 .hover-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.hover-2-title {
}

.hover-2-description {
  width: 100%;
  text-align: center;
  z-index: 99;
  transition: all 0.3s;
}

.hover-2-content {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  padding: 30px;
  z-index: 99;
}

.technology {
  padding: 80px 0;
  display: block;
  width: 100%;
}

.hover-pink:hover .hover-overlay {
  background: linear-gradient(
    to right,
    #a417d4 0%,
    rgba(164, 23, 212, 0.4) 1%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a417d4", endColorstr="#00ffffff",GradientType=1 );
  left: 0;
  bottom: 0;
  left: -4px;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  position: absolute;
}

.hover-sky:hover .hover-overlay {
  left: 0;
  bottom: 0;
  left: -4px;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  position: absolute;
  background: -moz-linear-gradient(
    left,
    rgb(2, 149, 250) 0%,
    rgba(2, 149, 250, 0.99) 1%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(2, 149, 250, 1) 0%,
    rgba(2, 149, 250, 0.99) 1%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgb(2, 149, 250) 0%,
    rgba(2, 149, 250, 0.44) 1%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0295fa', endColorstr='#00ffffff',GradientType=1 );
}

.buildheading h3 {
  /*  background: #78CEFF;
  background: linear-gradient(to right, #78CEFF 0%, #0295fa 100%);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
  margin: 0 0 40px 0;
}

/* DEMO GENERAL ============================== */
.hover {
  overflow: hidden;
  margin: 0 0 20px 0;
  position: relative;
  padding: 0%;
  border-radius: 40px;
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  transition: all 0.3s;
  height: 100%;
}

.hover-content {
  position: relative;
  z-index: 99;
}

.hover-2-content h3 {
  font-size: 44px;

  margin: 0 0 10px 0;
}

.hover-2-content span {
  font-size: 14px;

  margin: 0 0 15px 0;
  display: block; /* height: calc(100% - 210px); */
}

.hover-2-content p {
  font-size: 18px;

  margin: 0 0 0px 0;
}

.member-box img {
  border-radius: 10px;
  width: 100%;
}

.member-box {
  padding: 15px;
  transition: all 0.25s ease-in-out;
  -webkit-filter: blur(0) !important;
  filter: blur(0) !important;
}

.member-boxdown {
  height: 100px;
}

.member-boxdown h4 {
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.member-boxdown h5 {
  font-size: 15px;
}

.member-box:hover {
  cursor: pointer;
  background: linear-gradient(
    103.27deg,
    rgba(120, 206, 255, 0.2) 5.27%,
    rgba(2, 149, 250, 0.2) 63.42%
  );
}

.teamlist .menu-item-active {
  filter: blur(0);
}

.teamlist .memberInfo {
  display: flex;
  /*visibility: hidden; */
  position: relative;
  /* display: flex; */
  justify-content: space-between;
  display: none;
}

.teamlist li:hover .memberInfo {
  display: block;
}

.teamlist li:hover .memberInfo a img {
  top: 10px;
}

.memberInfo a img {
  position: absolute;
  top: -30px;
  left: 0;
}

.memberInfo p {
  display: flex;
  color: #0046b8;
  align-items: center;
  align-content: center;
}

.memberInfo img {
  width: 25px !important;
  border-radius: 0 !important;
}

.teamlist li:hover .memberInfo {
  /* display: flex; */
  visibility: visible;
  color: red;
}

.teamlist li:hover .left-arrow {
  display: none;
}

.teamlist {
  display: flex;
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
}

/*.teamlist li:hover {
  background:rgb(35 163 251 / 25%);
}*/

.teamlist li .member-box img {
  border-radius: 10px;
  width: 100%;
}

.member-box .left-arrow img {
  width: 25px !important;
}

.section-title.text-center.teamheading h4 {
  padding: 0;
  background: #78ceff;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 44px;
  margin: 0 0 25px 0;
}

.teamlist.teamnew {
  margin: 0;
}

.section-title.text-center.teamheading p {
  font-size: 18px;

  margin: 0 0 0px 0;
}

.section-title.text-center.teamheading {
  margin: 0 0 40px 0;
}

.eco-system .container {
  /*  box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, 0.1098039216);
border-radius: 10px;
padding: 30px;
background-image: linear-gradient(to right, #54bbfb, #43b3fb, #32aafb, #1fa2fb, #0a99fa); */
}

.partner-list li img {
  width: 100%;
}

.partner-list {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  position: relative;
  box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, 0.1098039216);
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    #54bbfb,
    #43b3fb,
    #32aafb,
    #1fa2fb,
    #0a99fa
  );
}

.partner-list li {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 30px;
}

.partner-list i {
  content: "";
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.partner-list i::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60%;
  height: 1350px;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    hsl(220deg 92% 38%) 0%,
    hsl(225deg 85% 40%) 6%,
    hsl(230deg 79% 41%) 12%,
    hsl(235deg 73% 43%) 18%,
    hsl(241deg 68% 45%) 24%,
    hsl(246deg 63% 47%) 29%,
    hsl(252deg 60% 48%) 35%,
    hsl(259deg 63% 48%) 41%,
    hsl(265deg 66% 47%) 47%,
    hsl(272deg 69% 46%) 53%,
    hsl(279deg 73% 46%) 59%,
    hsl(286deg 76% 45%) 65%,
    hsl(296deg 75% 46%) 71%,
    hsl(306deg 71% 47%) 76%,
    hsl(317deg 67% 49%) 82%,
    hsl(328deg 65% 50%) 88%,
    hsl(338deg 65% 52%) 94%,
    hsl(349deg 66% 54%) 100%
  );
}

#ngrecaptcha-0 iframe {
  max-width: 100%;
  transform: scale(1);
  -webkit-transform: scale(1);
}

#ngrecaptcha-1 iframe {
  max-width: 100%;
  transform: scale(1);
  -webkit-transform: scale(1);
}

.ecoinn h2 {
  padding: 0;
  background: #78ceff;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 44px;
  margin: 0 0 50px 0;

  text-transform: uppercase;
}

.banefits {
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/banefits-fullbg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 1% 0 2% 0;
}

.stadium {
  height: 700px;
  width: 100%;
  background-image: url("./assets/images/stadium.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding: 6% 0;
  position: relative;
}

.metafoolballtop h2 {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 50%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 44px;

  display: inline-block;
  text-align: center;
}
.metafoolballtop h6 {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 50%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;

  display: inline-block;
  text-align: center;
}
.metafoolballtop p {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
}

.metafoolballtop {
  text-align: center;
}

.footballlist {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 40px auto 0;
  text-align: center;
}

.footballlist li {
  display: inline-flex;
  margin: 0 100px 0px 0;
}

.footballlist li:last-child {
  margin: 0;
}

.rightfootball h3 {
  font-style: normal;

  font-size: 21px;
  margin: 0;
  color: #1b1b1b;
  line-height: 30px;
  text-align: left;
  font-weight: bold;
}

.leftfootball {
  margin: 0 20px 0px 0;
}

.playmain {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  text-decoration: none;
  width: 230px;
  margin: 0 auto;
  padding: 10px 0px;
  font-size: 22px;
  text-align: center;
  border: none !important;
  outline: none !important;
  color: white;
  font-weight: bold;
}

.nftreal {
  background-image: linear-gradient(
    to left,
    #a1d0f0,
    #9ec6f2,
    #a1baf0,
    #abadec,
    #b89fe2
  );
}

.nftlist {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
}

.nftlist li {
  padding: 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: inset 29.8667px -29.8667px 29.8667px rgba(46, 135, 192, 0.1),
    inset -29.8667px 29.8667px 29.8667px rgba(255, 255, 255, 0.1);
  width: 50%;
  float: left;
  filter: drop-shadow(4px 20px 40px rgba(0, 0, 0, 0.25));
}

/* .gapone {
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 99;
}

.gaptwo {
  position: absolute;
  left: 191px;
  top: 60px;
} */

.gaptwo {
  margin: 55px 0 0 -10px;
}

.toplistnft {
  background: #e0e0e0;
  border-radius:0px;
  width: 100%;
  margin: 0 0 0px 0;
  overflow: hidden;
}

.downlistnft h2 {
  font-style: normal;

  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #0e0e0e;
}

.downlistnft p {
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #0e0e0e;
}

.downlistnft span {
  font-style: normal;

  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  background: radial-gradient(
    136.03% 6755.5% at 111.63% -36.03%,
    #eb4a26 0%,
    #a417d4 45.31%,
    #0046b8 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.press-releasemain {
  width: 100%;
  background-image: url("https://static.metabloqs.com/web/assets1/images/press-release-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding: 6% 0;
  position: relative;
}

.socialicon {
  padding: 0;
  margin: 50px 0 0 0;
  list-style: none;
  display: flex;
  position: relative;
}

/* .socialicon li {
  width: 200px;
}
 */

.socialicon li img {
  /*  width: 100%; */
  width: 100px;
}

.socialicon .socialiconone img {
  /*  box-shadow: rgba(50, 50, 93, 0.33) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 18px -8px; */
  float: left;
  z-index: 9;
  display: inline-block;
  width: 100px;
  background: #fff;
  height: 100px;
  border-radius: 40px;
  box-sizing: border-box;
  margin: 0 10px 0 0;
}

.socialiconone {
  position: absolute;
  left: 0;
  top: 70px;
}

.socialicontwo {
  position: absolute;
  left: 110px;
  top: -40px;
}

.socialiconthree {
  position: absolute;
  left: 295px;
  top: 30px;
}

.socialiconfour {
  position: absolute;
  left: 505px;
  top: -30px;
}

.press-releaselast {
  width: 100%;
  height: 250px;
}

.socialiconfive {
  position: absolute;
  left: 645px;
  top: 55px;
}

.socialiconsix {
  position: absolute;
  left: 835px;
  top: -30px;
}

.socialiconseven {
  position: absolute;
  right: 0;
  top: 90px;
}

.visibleboxmain {
  display: inline-block;
  box-shadow: rgba(50, 50, 93, 0.33) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 18px -8px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  text-align: left;
  overflow: hidden;
  border-radius: 43px;
  background: #fff;
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

/* .visibleboxmain:hover {
  width: 415px;
  z-index: 99;
  position: relative;
}
 */

.visibleboxinn p {
  font-size: 14px;

  margin: 0px 0 0px 0;
  color: #000;
  white-space: break-spaces;
}

.visibleboxinn h2 {
  color: #000;

  font-style: normal;

  font-size: 26px;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 11px 0 0 0;
}

.press-release h2 {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 44px;

  margin: 0px 0 20px 0;
  display: inline-block;
}

.press-release p {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
  position: relative;
}

.press-release p::after {
  width: 110px;
  height: 0;
  border: 3px solid rgba(0, 0, 0, 0.9);
  content: "";
  border-radius: 20px;
  text-align: center;
  display: block;
  margin: 30px auto 0;
}

.press-release p span {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.press-release {
  text-align: center;
}

.socialtxt h2 {
  font-style: normal;

  font-size: 44px;
  line-height: 95.8%;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
}

.socialtxt {
  text-align: center;
}

.socialtxt span {
  font-style: normal;

  font-size: 24px;
  background: radial-gradient(
    136.03% 6755.5% at 111.63% -36.03%,
    #eb4a26 0%,
    #a417d4 45.31%,
    #0046b8 100%
  );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  display: inline-block;
  margin: 30px 0 0 0;
}

.socialtxt a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  gap: 12px;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 10px;

  font-style: normal;

  font-size: 20px;
  color: #fff;
  text-decoration: none;
  margin: 40px auto 0;
  text-align: center;
  width: 180px;
}

.press-releasemid {
  width: 55%;
  margin: 40px auto;
}

.our-team {
  padding: 6% 0 0;
}

.testimain img {
  width: 35px !important;
}

.ecoinn h2::before {
  width: 120px;
  height: 6px;
  content: "";
  border-radius: 20px;
  text-align: center;
  display: block;
  margin: 30px auto 20px;
  background: linear-gradient(100.9deg, #78ceff -58.05%, #058be7 77.35%);
}

.testimain {
  max-width: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
  padding: 30px;
  background: transparent;
  margin: 0 0 60px 0;
}

.testimain i:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60%;
  height: 1160px;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    #0843ba 0%,
    #0f3bbd 6%,
    #1631bb 12%,
    #1e2bbe 18%,
    #2725c1 24%,
    #3b2cc3 29%,
    #4e31c4 35%,
    #5e2dc8 41%,
    #6b29c7 47%,
    #7b24c6 53%,
    #8f20cb 59%,
    #a11cca 65%,
    #c21dcd 71%,
    #cd23bc 76%,
    #d129a1 82%,
    #d22d85 88%,
    #d4356f 94%,
    #d73c59 100%
  );
}

.testimain i {
  content: "";
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.testimainleft p {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  color: #000;
  position: relative;
}

.testimainleft {
  width: 75%;
  display: inline-block;
}

.testimainright {
  width: 20%;
  float: right;
}

.testimainright img {
  width: 100% !important;
  border-radius: 100px;
}

.testimainleft h3 {
  font-size: 20px;

  margin: 10px 0 0 0;
}

.press-releasemid .owl-nav {
  position: absolute;
  margin: 0;
  top: 7vw;
  width: 100%;
}

.press-releasemid .owl-nav .owl-prev {
  left: -50px;
  position: absolute;
  background: transparent;
}

.press-releasemid .owl-nav .owl-next {
  position: absolute;
  right: -50px;
  background: transparent;
}

.press-releasemid .owl-prev img {
  width: 60%;
}

.press-releasemid .owl-next img {
  width: 60%;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  text-decoration: none;
}

.press-releasemid .owl-theme .owl-dots span {
  text-align: center !important;
  margin-top: 40px !important;
}
.press-releasemid .owl-theme .owl-dot span {
  width: 45px !important;
  height: 3px !important;
  margin: 5px 7px !important;
  display: block !important;
  -webkit-backface-visibility: visible !important;
  transition: opacity 0.2s ease !important;
  border-radius: 30px !important;
  background: #fff;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
}

.bluebox {
  width: 100%;
  height: 280px;
  background-image: url("./assets/images/blue-frame.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 40px 0 0 0;
  position: relative;
}

.our-team .container {
  background: transparent;
  box-shadow: inherit;
  border-radius: 0;
}

.blueboxinn .button.button-blue {
  float: right;
}

.blueboxinn {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  width: 75%;
  margin: 0 auto;
  display: block;
  z-index: 8;
  position: relative;
}

.blueboxinn h3 {
  line-height: 45px;
  padding-bottom: 0px;
  margin: 0 0 0px 0;
  font-size: 34px;
  display: block;
  background: #78ceff;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bluebox::before {
  content: "";
  width: 100%;
  height: 270px;
  background-image: url("./assets/images/line1.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: 5px;
  background-size: 100% 100%;
  z-index: 99;
  overflow: hidden;

  animation: MoveUpDown 5s linear infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

.bluebox::after {
  content: "";
  width: 100%;
  height: 150px;
  background-image: url("./assets/images/line2.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: 3px;
  background-size: 102% 100%;
  z-index: 0;
  animation: MoveDownUp 5s linear infinite;
}

@keyframes MoveDownUp {
  0%,
  100% {
    bottom: 30px;
  }
  50% {
    bottom: 0px;
  }
}

.section-boddymodel {
  display: flex;
  width: 100%;
}

.section-boddymodelleft {
  margin: 0 18px 0 0;
  width: 50%;
  float: left;
}

.section-boddymodelleft img {
  width: 100%;
}

.section-boddymodelright {
  width: 60%;
}

.section-boddymodelright h1 {
  margin: 0;
  color: #000;
  padding-bottom: 8px;
  font-size: 18px;
}

.section-boddymodelright p {
  color: #000;
  margin: 0 0 6px;
  line-height: 20px;

  font-size: 13px;
}

#close_btn_registerone {
  position: absolute;
  background-color: #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  line-height: 30px;
  font-size: 11px;

  bottom: -20%;
  left: 16.5vw;
}

footer {
  padding-top: 40px;
  padding-bottom: 20px;
  background: linear-gradient(265.2deg, #0479d8 0%, #1f87f5 107.45%);
}

.footer-links {
  padding: 0;
  margin: 0;
}

.foot-logo {
  margin-bottom: 15px;
}
.footer-info h4 {
  font-size: 24px;
  color: #fff;
  padding-bottom: 15px;
}
.footer-links li {
  padding-bottom: 10px;
  list-style: none;
}
.footer-links li:last-child {
  padding-bottom: 0;
}
.footer-links li a {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-decoration: none;
}

.footer-social {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  padding: 16px 0 0 0;
}
.footer-social li {
  padding: 0 8px;
  list-style: none;
}

.footer-bottom {
  font-size: 18px;
  color: #fff;
  margin: 30px 0 0 0;
  text-align: center;
}

.footer-social li a img {
  width: 25px;
}

/* .banefits-box:hover {
  background-size: 125%;background-size: 160% 160%;
}
 */

/* .banefits-box:hover .opone {
  transform: translate3d(0, 0, 0);
}
 */

.opone {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0px;
  transition: transform 300ms;
  transform: translate3d(0, 0, 0);
  /*  background-image: linear-gradient(to right, #54bbfb, #43b3fb, #32aafb, #1fa2fb, #0a99fa); */

  background-color: RGBA(255, 255, 255, 0.51);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banefits-box {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 36px;
  overflow: hidden;
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  border: 0;
  background-size: 105%;
  background-position: 50%;
  transition: all 0.5s ease-out;
  background-size: 100% 100%;
}

.modal-dialog.whitemodel {
  width: 100%;
  max-width: 100%;
  margin: 0rem auto;
}

.loginformmain p {
  font-style: normal;

  font-size: 16px;
  color: #fff;
}

.logingrayinn i {
  content: "";
  z-index: -1;
  position: absolute;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.modal-body.schedulebody .btn-close {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 90px;
  background-size: 14px 20px;
}

.logingrayinn i::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100vh;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    #0843ba 0%,
    #0f3bbd 6%,
    #1631bb 12%,
    #1e2bbe 18%,
    #2725c1 24%,
    #3b2cc3 29%,
    #4e31c4 35%,
    #5e2dc8 41%,
    #6b29c7 47%,
    #7b24c6 53%,
    #8f20cb 59%,
    #a11cca 65%,
    #c21dcd 71%,
    #cd23bc 76%,
    #d129a1 82%,
    #d22d85 88%,
    #d4356f 94%,
    #d73c59 100%
  );
}

.framregmain {
  align-items: center !important;
  justify-content: center !important;
  display: inline-flex;
  height: 100%;
}
.inner-sidebar {
  padding: 5% 0 5%;
}

.modal-body.popcontentinn.lightbg {
  padding: 0;
}

#close_btn_register {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  right: 20px;
  top: 11%;
  line-height: 30px;
  font-size: 18px;
}

.startscreen img {
  width: 100%;
  margin: 0 0 80px 0;
}
.startscreen {
  text-align: center;
}

.animationtext {
  color: #ff9d5c;
  padding: 1em;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  color: #fff;
  display: block;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;

  transition: all 0.5s ease;
  padding: 10px 0px 10px;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 21px;

  text-transform: uppercase;
  display: block;
}
.animationtext span {
  display: inline-block;
  max-width: 20px;
  animation: flows 4s ease-in-out infinite;
}
@keyframes flows {
  50% {
    color: #023495;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  }
}
.animationtext span:nth-child(1) {
  animation-delay: 0.2s;
}
.animationtext span:nth-child(2) {
  animation-delay: 0.4s;
}
.animationtext span:nth-child(3) {
  animation-delay: 0.6s;
}
.animationtext span:nth-child(4) {
  animation-delay: 0.8s;
}
.animationtext span:nth-child(5) {
  animation-delay: 1s;
}
.animationtext span:nth-child(6) {
  animation-delay: 1.2s;
}
.animationtext span:nth-child(7) {
  animation-delay: 1.4s;
}
.animationtext span:nth-child(8) {
  animation-delay: 1.6s;
}
.animationtext span:nth-child(9) {
  animation-delay: 1.8s;
}
.animationtext span:nth-child(10) {
  animation-delay: 2s;
}
.animationtext span:nth-child(11) {
  animation-delay: 2.2s;
}
.animationtext span:nth-child(12) {
  animation-delay: 2.4s;
}
.animationtext span:nth-child(13) {
  animation-delay: 2.6s;
}
.animationtext span:nth-child(14) {
  animation-delay: 2.8s;
}
.animationtext span:nth-child(15) {
  animation-delay: 3s;
}
.animationtext span:nth-child(16) {
  animation-delay: 3.2s;
}
.animationtext span:nth-child(17) {
  animation-delay: 3.4s;
}
.animationtext span:nth-child(18) {
  animation-delay: 3.6s;
}
.animationtext span:nth-child(19) {
  animation-delay: 3.8s;
}
.animationtext span:nth-child(20) {
  animation-delay: 4s;
}
.animationtext span:nth-child(21) {
  animation-delay: 4.2s;
}
.animationtext span:nth-child(22) {
  animation-delay: 4.4s;
}
.animationtext span:nth-child(23) {
  animation-delay: 4.6s;
}
.animationtext span:nth-child(24) {
  animation-delay: 4.8s;
}
.animationtext span:nth-child(25) {
  animation-delay: 5s;
}
.animationtext span:nth-child(26) {
  animation-delay: 5.2s;
}
.animationtext span:nth-child(27) {
  animation-delay: 5.4s;
}

.animationtext span:nth-child(28) {
  animation-delay: 5.6s;
}

.animationtext span:nth-child(29) {
  animation-delay: 5.8s;
}

.animationtext span:nth-child(30) {
  animation-delay: 6s;
}

.animationtext span:nth-child(31) {
  animation-delay: 6.2s;
}

.animationtext span:nth-child(32) {
  animation-delay: 6.4s;
}

.animationtext span:nth-child(33) {
  animation-delay: 6.6s;
}

.animationtext span:nth-child(34) {
  animation-delay: 6.8s;
}
.animationtext span:nth-child(35) {
  animation-delay: 7s;
}

.animationtext span:nth-child(36) {
  animation-delay: 7.2s;
}

.roundlogo {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.midlogo {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.selectionamin p {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.userbtntwo {
  padding: 0;
  list-style: none;
  display: flex;
  text-align: center;
  margin: 40px auto 0;
  justify-content: center;
}

.userbtntwo li {
  margin: 0 30px 0 0;
}

.userbtntwo li:last-child {
  margin: 0;
}

.guestbtn {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  text-decoration: none;

  color: #fff !important;
  margin: 0 auto;
  padding: 10px 90px;

  font-size: 18px;
  text-align: center;
}

.walletbtn {
  position: relative;
  background-color: RGBA(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 100px;
  padding: 10px 70px;

  font-size: 18px;
  text-decoration: none;
  color: #fff;
}

.walletbtn i::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 78vh;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    #0843ba 0%,
    #0f3bbd 6%,
    #1631bb 12%,
    #1e2bbe 18%,
    #2725c1 24%,
    #3b2cc3 29%,
    #4e31c4 35%,
    #5e2dc8 41%,
    #6b29c7 47%,
    #7b24c6 53%,
    #8f20cb 59%,
    #a11cca 65%,
    #c21dcd 71%,
    #cd23bc 76%,
    #d129a1 82%,
    #d22d85 88%,
    #d4356f 94%,
    #d73c59 100%
  );
}

.walletbtn i {
  content: "";
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.inner-login.loginpage {
  height: auto;
}

.sect.section1.regmain {
  width: 100%;
  position: relative;
  z-index: 1;
  /* background: linear-gradient(
    103.88deg,
    rgba(11, 174, 255, 0.5) 0.08%,
    rgba(0, 97, 252, 0.5) 100.33%
  ); */
  box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, 0.10980392156862745);
  margin-top: 5%;
}

.leftprofile {
  /* height: 80vh; */
  background: #48aadf;
  background: linear-gradient(180deg, #48aadf 0, #2c8ccb);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#48aadf",endColorstr="#2c8ccb",GradientType=0);
  border-radius: 20px;
  position: relative;
}

.user-newpro {
  color: #fff;
  font-size: 24px;
  padding: 15px;
  margin: 0 0 10px;

  font-style: normal;
}

.nav-pills.leftprofilebtninn .nav-link.active {
  background: #55bdfd;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#55bdfd",endColorstr="#0095f8",GradientType=1);
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
}

#v-pills-tab .nav-link img {
  float: left;
  margin: 0 10px 0 0;
}

#v-pills-tab .nav-link.active {
  border-radius: 15px;
}
#v-pills-tab .nav-link {
  padding: 10px 15px;
  color: #fff;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  text-align: left;
  font-size: 18px;
}
.nav-pills.leftprofilebtninn .nav-link.active i {
  visibility: visible;
}
.leftprofilebtninn .nav-link i {
  position: absolute;
  right: 0;
  visibility: hidden;
}

.nav-link.bottomlogout {
  /* position: absolute;
  bottom: 0;
  left: 0;
  margin: 95% 0 0 0 !important; */
}
.code.codeonetop.newtopcode {
  margin: 0 0 30px;
  position: relative;
  padding: 2px;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    #0843ba 0%,
    #0f3bbd 6%,
    #1631bb 12%,
    #1e2bbe 18%,
    #2725c1 24%,
    #3b2cc3 29%,
    #4e31c4 35%,
    #5e2dc8 41%,
    #6b29c7 47%,
    #7b24c6 53%,
    #8f20cb 59%,
    #a11cca 65%,
    #c21dcd 71%,
    #cd23bc 76%,
    #d129a1 82%,
    #d22d85 88%,
    #d4356f 94%,
    #d73c59 100%
  );
}

.codeonetopinn {
  background: #fff;
  padding: 30px 13px;
  border-radius: 10px;
}

.code.codeonetop h5 {
  background: #82b7e5;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px;
  font-size: 40px;
  color: #000;

  display: block;
}

.code h1 {
  font-size: 22px;
  margin: 0 0 15px;
  color: #222;
}

.code p {
  font-size: 18px;
  color: #222;
  margin: 10px 0;
  font-size: 18px;
}

.banefits-box.banefits-box-type-2.earn-bane {
  height: 323px;
}

.code {
  color: #fff;
  background: #fff;
  padding: 25px 20px;
  border-radius: 10px;
  margin: 0 0 0px 0;
}

.code.earncode h3 {
  color: #000;

  font-size: 22px;

  margin: 0 0 13px;
}

.reffcode {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.reffcode li {
  display: block;
  margin: 0 0 20px;
}

.codeleft {
  float: left;
  margin: 0 10px 0 0;
  width: 50px;
}

.codeleft img {
  width: 100%;
}

.coderight {
  position: relative;
}

.coderight span {
  display: block;
  text-align: left;

  font-size: 16px;
  color: #222;
}

.coderight a,
.coderight h2 {
  font-size: 12px;
  color: #222;
  text-align: left;
}

.code a {
  font-size: 10.5px;
  color: #a020f0;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  display: block;
  text-decoration: none;
}

.copytxt {
  display: inline-block !important;
  width: 27px;
  height: 27px;
  text-align: center;
  margin: 0 0 0 0px;
  border-radius: 90px;
  padding: 5px;
  background: #55bdfd;
  background: linear-gradient(90deg, #55bdfd 0, #0095f8);
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: -16px;
  line-height: 5px;
}

.copytxt img {
  width: 100%;
  height: 100%;
}

.code.earncode:last-child {
  margin: 25px 0 0;
}

.code.earncode .footer-social.codelsocial {
  padding: 0;
}
.footer-social.codelsocial {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.footer-social.codelsocial li {
  display: inline-block;
}
.footer-social li {
  padding: 0 7.5px;
}

.footer-social.codelsocial li a {
  background: #55bdfd;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  width: 40px !important;
  height: 40px !important;
  display: block;
  border-radius: 100px;
  line-height: 60px;
  text-align: center;
}

.footer-social li a img {
  width: 25px;
  vertical-align: inherit;
}

.tableback {
  padding: 0px;
  border-radius: 10px;
}

.table-fill {
  padding: 0;
  width: 100%;
}

.table-hover.nocolor_table tr {
  border-radius: 10px;
  background: #fff;
}
tr:first-child {
  border-top: none;
}

.table-hover.nocolor_table tr td {
  background: transparent;
  border: 0;
  padding: 10px;
  font-size: 18px;
}

.table-hover input,
.table-hover select {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
}

.round-blue {
  text-align: center;
  padding: 5px;
  width: 40px;
  border-radius: 100px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  background: #55bdfd;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
}

.round-blue img {
  width: 75%;
  vertical-align: inherit;
}

.profilebtnbox p {
  font-style: normal;

  font-size: 16px;
}

.code.codeonetop h2 {
  background: #82b7e5;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px;
  font-size: 22px !important;
  color: #000;

  display: block;
  text-align: center;
  line-height: 30px !important;
}

.banefits-box h3 {
  font-size: 22x;
  line-height: 45px;
  text-align: left;
}
.banefits-box h2,
.banefits-box h3 {
  color: #fff;
}

.yellowbox.registermain .sec-box {
  width: auto;
}

.registermain .sec-box span {
  font-size: 32px;
  margin: 20px 0 5px;

  color: #000;
  line-height: 50px;
  text-align: left;
  display: block;
}

.registermain .sec-box p {
  font-size: 18px;

  margin: 0;
  text-align: left;
  color: #000;
  padding: 0;
}

.bigsecinput.rewardnew {
  background: #fff;
  padding: 13px;
  border-radius: 10px;
}
.bigsecinput {
  margin: 40px 0;
  display: block;
  width: 100%;
}

.rewardleft,
.rewardleft img {
  width: 100%;
}

.noformeffect {
  background: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.noformeffect .form-group {
  margin: 0 0 10px;
}

.noformeffect .inputboxupp {
  margin: 0;
  width: auto;
}
.inputboxupp {
  position: relative;
  font-size: 1.5em;
  background: linear-gradient(
    90deg,
    rgba(235, 74, 38, 0.44) 40%,
    rgba(164, 23, 212, 0.44) 50%,
    rgba(0, 70, 184, 0.44) 62%
  );
  padding: 3px;
  border-radius: 10px;
  width: 70%;
  margin: 20px auto 0;
  display: flex;
}

.noformeffect input::placeholder {
  color: rgba(2, 149, 250, 0.8705882352941177) !important;
}

#applyForm input,
#applyForm select {
  background: #f7f7f7 none repeat scroll 0 0;
  border-radius: 7px;
  font-size: 14px;
  height: 50px;
  border: 1px transparent;
}
#applyForm input {
  line-height: 50px;
}

.col-md-12.btninfomainleft {
  width: 93%;
}

.col-md-1.btninfomain {
  width: 4%;
  padding: 0;
}
.noformeffect .form-group {
  margin: 0 0 10px;
}
.form-group.btninfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.form-group.btninfo a {
  display: block;
  height: 17px;
}

.profilebtnbox {
  margin: 10px 0 0;
}

.profilebtnbox p a {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}

.form-group.btninfo a img {
  width: 100%;
}

.apply-now.btn.btn-info.savebtn {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  color: #fff;
  display: block;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 22px;
  transition: all 0.5s ease;
  padding: 10px 35px 10px;
  border: 1px solid transparent;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;
  margin: 20px 0;
}

.bigsecinput.rewardnew p {
  font-style: normal;

  font-size: 16px;
  margin: 0px;
}

.bannervideo {
  width: 100%;
  position: relative;
  /*  height: calc(100vh + 350px);*/
  height: 100%;
}

.bannervideo video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.topheaderone .navbar {
  width: 100%;
  padding: 0px;
}

.topheaderone .navbar-nav {
  display: block;
  margin: 0 auto;
}

.topheaderone .navbar-toggler {
  background: linear-gradient(
    102.66deg,
    #78ceff -17.72%,
    #0295fa 80.89%
  ) !important;
  border: 0px;
  padding: 7px;
}

.topheaderone .navbar-toggler:focus {
  box-shadow: inherit;
}

.hidepanel {
  display: none;
}

.reffcode li:last-child {
  margin: 0;
}

.buildheading h1 {
  padding: 0;
  background: #78ceff;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 22px;
}

.scheduledemo {
  text-align: center;
}

.scheduledemo a {
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
  color: #fff;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 100px;
  transition: all 0.5s ease;
  padding: 15px 35px 15px;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;

  text-decoration: none;
  margin: 20px 0 0 0;
}

.scheduledemo p {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
  margin: 30px 0 0px 0;
}

.scheduleinn input {
  width: 100%;
  margin: 0 0 20px !important;
  display: block;
  background: #fff;
  height: 35px;
  border: 0;
  font-style: normal;

  font-size: 15px;
  line-height: 23px;
  color: #caced8;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.scheduleinn textarea {
  width: 100%;
  margin: 0 0 20px !important;
  display: block;
  background: #fff;
  height: 100px;
  border: 0;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  color: #caced8;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.scheduleinn button {
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 10px;
  border: 0;
  display: block;
  width: 100%;
  padding: 8px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #fff;
}

.modal-content.schedulecontent {
  background: transparent;
  border: 0;
}

.modal-body.schedulebody {
  padding: 0;
}

.modal-body.schedulebody h2 {
  font-size: 36px;
  line-height: 47px;
  color: #fff;
  margin: 0 0 20px;
  font-weight: bold;
}

.wrappersub {
  height: 60px;
  width: 400px;
  margin: 25px 0 0 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wrappersub .field {
  height: 100%;
  width: 100%;
  position: relative;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrappersub .field input {
  height: 100%;
  width: 100%;
  border: 2px solid #e2eff7;
  border-radius: 5px;
  padding: 0 130px 0 15px;
  font-size: 20px;
  outline: none;
  font-style: normal;

  font-size: 20px;
  background: #e2eff7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.wrappersub .field input::placeholder {
  color: #999;
  font-size: 18px;
}
.wrappersub .field .btn-2 {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  height: 40px;
  width: 130px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);

  font-style: normal;

  font-size: 20px;
}

.blu p {
  font-size: 18px;

  margin: 0 0 0px 0;
}

.nav.banefitsmain .nav-link.active span {
  font-size: 14px;
  color: #67808e;
  text-align: left;
}

.nav.banefitsmain .nav-link span {
  color: #67808e;
  font-size: 14px;
  margin: 0 0 10px 0;
  display: block;
  text-align: left;
}

.metapartner h1 {
  font-style: normal;

  font-size: 44px;
  line-height: 95.8%;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
}

.metapartner p {
  font-style: normal;

  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
  margin: 0 0 40px 0;
}

.createitem_upload-button {
  background: #1c83e5;
  border-radius: 1em;
  padding: 0.5em 1em;
  color: white;
  border: 0.5px solid #1c83e5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newdashboard {
  padding: 80px 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.leftdashboard {
  box-shadow: 0px 8px 18px rgba(20, 18, 103, 0.03), 0px 2px 20px rgba(23, 58, 110, 0.03);
  padding: 15px;
  width: "100%";
  background: #fff;
  text-align: center;
  height:100%;
  position: fixed;
}

.dashboard-logo img {
  width: 45px;
}
.dashboard-logo {
  margin: 30px 0 50px 0;
  display: block;
  text-align: center;
}

.dashmenu {
  padding: 0;
  list-style: none;
  margin: 0;
  width: 100%;
  text-align: center;
}

.dashmenu li {
  margin: 0 0 30px 0;
}

.dashmenu button span {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 16px;

  margin: 0px 0 0px 0;
  display: block;
  text-decoration: none;
}

.dashmenuone {
  background-image: url("./assets/images/Dashboard-menu.png");
  background-repeat: no-repeat;
  height: 60px;
  background-position: center;
  /*padding: 10px;
 background-color: red; */
  display: inline-block;
  border-radius: 40px;
  width: 60px;
  line-height: 40px;
}

.dashmenutwo {
  background-image: url("./assets/images/Profile-menu.png");
  background-repeat: no-repeat;
  height: 60px;
  background-position: center;

  display: inline-block;
  border-radius: 40px;
  width: 60px;
  line-height: 40px;
}

.dashmenugame {
  background-image: url("./assets/images/Analytics-menu.png");
  background-repeat: no-repeat;
  height: 60px;
  background-position: center;
  /*padding: 10px;
 background-color: red; */
  display: inline-block;
  border-radius: 40px;
  width: 60px;
  line-height: 40px;
}

.dashmenuprofile {
  background-image: url("./assets/images/Profile-menu.png");
  background-repeat: no-repeat;
  height: 60px;
  background-position: center;
  /*padding: 10px;
 background-color: red; */
  display: inline-block;
  border-radius: 40px;
  width: 60px;
  line-height: 40px;
}

.dashmenufive {
  background-image: url("./assets/images/message-menu.png");
  background-repeat: no-repeat;
  height: 60px;
  background-position: center;

  display: inline-block;
  border-radius: 40px;
  width: 60px;
  line-height: 40px;
}

.dashmenulogout {
  background-image: url("./assets/images/logout.png");
  background-repeat: no-repeat;
  height: 60px;
  background-position: center;

  display: inline-block;
  border-radius: 40px;
  width: 60px;
  line-height: 40px;
}

/* 
.dashmenuthree {
   background-image: url("https://static.metabloqs.com/web/assets1/images/activities.png");
background-repeat: no-repeat;
height: 60px;
background-position: center;

display: inline-block;
border-radius: 40px;
width: 60px;
line-height: 40px;
}


.dashmenufour {
   background-image: url("https://static.metabloqs.com/web/assets1/images/Settings-menu.png");
background-repeat: no-repeat;
height: 60px;
background-position: center;

display: inline-block;
border-radius: 40px;
width: 60px;
line-height: 40px;
}




.dashmenusix {
   background-image: url("https://static.metabloqs.com/web/assets1/images/Analytics-menu.png");
background-repeat: no-repeat;
height: 60px;
background-position: center;

display: inline-block;
border-radius: 40px;
width: 60px;
line-height: 40px;
}

.dashmenuseven {
   background-image: url("https://static.metabloqs.com/web/assets1/images/Support-menu.png");
background-repeat: no-repeat;
height: 60px;
background-position: center;
display: inline-block;
border-radius: 40px;
width: 60px;
line-height: 40px;
} */

.leftdashboard #v-pills-tab .nav-link {
  display: block;
  padding: 0;
  box-shadow: inherit;
  text-align: center;
}

.leftdashboard .nav-pills .nav-link span {
  background: #0744b9;
  background: linear-gradient(to right, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 16px;

  margin: 0px 0 0px 0;
  display: block;
  text-decoration: none;
}

.leftdashboard .nav-pills .nav-link.active span {
  background: transparent;
  background-clip: border-box;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active {
  background: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenuone {
  background-image: url("./assets/images/Menu1.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenutwo {
  background-image: url("./assets/images/Menu2.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenuthree {
  background-image: url("./assets/images/Menu3.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenufour {
  background-image: url("./assets/images/Menu4.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenufive {
  background-image: url("./assets/images/Menu5.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenuprofile {
  background-image: url("./assets/images/Menu2.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenugame {
  background-image: url("./assets/images/Menu6.png");
  background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenulogout {
  background-image: url("./assets/images/Menu9.png");
  background-color: transparent;
}

/* .leftdashboard .nav-pills .nav-link.active .dashmenusix {
    background-image: url("https://static.metabloqs.com/web/assets1/images/Menu6.png");background-color: transparent;
}

.leftdashboard .nav-pills .nav-link.active .dashmenuseven {
    background-image: url("https://static.metabloqs.com/web/assets1/images/Menu7.png");background-color: transparent;
}
 */

.dashmenu button {
  padding: 6px 0;
  display: block;
  width: 100%;
}

.loginformmain.newslettinn .form-check {
  padding: 0;
  margin: 0 0 15px 0;
}

.loginformmain.newslettinn .form-check-input {
  width: 1em;
  height: 1em;
  margin: 0px 10px 0 0 !important;
}

.loginformmain.newslettinn .form-check-label {
  font-style: normal;

  font-size: 16px;
  color: #000;
}

.loginformmain.newslettinn #loginbtnn {
  margin: 0 0 0 0;
}

.midformcheck {
  margin: 0 0 0 28px;
}

.midformcheck .form-check {
  margin: 0 0 10px 0px !important;
}

.examplesearchinn {
  width: 100%;
  position: relative;
}

.examplesearchinn button {
  background: #eef4ff;
  border-radius: 40px 0 0 40px;
  border: 0;
  height: 51px;
  float: left;
  width: 50px;
}

.examplesearchinn input {
  background: #eef4ff;
  border-radius: 0px 40px 40px 0;
  border: 0;
  width: 87%;

  font-size: 18px;

  color: #1b1b1b;
  padding: 12px 0;
}

.prodilesearchbar {
  margin: 0 0 40px 0;
  width: 100%;
}

.examplesearchmain {
  width: 57%;
}

.midpro {
  margin: 0 40px;
}

.midprosec img {
  width: 23px;
  float: left;
  margin: 0 0 0 15px;
}

.midpro p {
  font-size: 16px;

  margin: 0 0 0px;
  color: #1b1b1b;
}

.dropdown.procontent a span {
  font-size: 16px;

  margin: 0 0 0px;
  color: #1b1b1b;
}

.mapcomtopleft {
  background: #fff;
  box-shadow: -5px 3px 23px rgba(20, 18, 103, 0.03),
    0px 13px 36px rgba(23, 58, 110, 0.01);
  border-radius: 24px;
  width: 100%;
  padding: 20px;
  margin: 0 0 40px 0;
}

.mapcomtoplefttop h2 {
  font-style: normal;

  font-size: 20px;
  line-height: 40px;
  display: inline-block;
  margin: 0;
}

.mapcomtoplefttop {
  width: 100%;
}

.mapcomtoplefttop a {
  float: right;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  text-decoration: none;

  font-size: 15px;

  color: #1b1b1b;
  padding: 0px 9px;
  margin: 7px 0;
}
.mapcomtoplefttop a img {
  margin: -6px 0 0 0;
}

.maploc {
  padding: 0;
  margin: 20px 0 0 0;
  list-style: none;
  width: 100%;
}

.maploc li {
  display: inline-block;
  margin: 0 0 10px 0;
}

.roundgreyleft {
  background: #c4c4c4;
  display: inline-block;
  width: 55px;
  height: 55px;
  border-radius: 40px;
  position: relative;
}

.roundgreyleft span {
  background: #333;
  border: 2px solid #fff;
  width: 22px;
  height: 22px;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 50px;

  font-size: 12px;

  position: absolute;
  left: -5px;
}

.roundgreymain {
  display: flex;
}

.roundgreyright h3 {
  font-style: normal;

  font-size: 16px;
  line-height: 55px;
  color: #1b1b1b;
  margin: 0 0 0 10px;
}

.maploc li a {
  display: block;
  text-decoration: none;
}

.highlightlist {
  padding: 0;
  margin: 30px 0 0 0;
  list-style: none;
}
.highlightlistinn {
  padding: 10px;
  width: 100%;
  display: inline-block;
}

.highlightlist li {
  background: #ffffff;
  box-shadow: 5px 0px 23px rgba(20, 18, 103, 0.06),
    0px 13px 36px rgba(23, 58, 110, 0.12);
  border-radius: 24px;
}

.highlightlistinntop {
  background: #eef4ff;
  height: 140px;
  border-radius: 28px;
  margin: 0 0 10px 0;
}

.highlightlistdown h4 {
  font-style: normal;

  font-size: 16px;
  color: #1b1b1b;
  margin: 0 0 0 0px;
}

.listhight {
  margin: 10px 0 0 0;
  display: flex;
}

.listhightleft {
  background: #c4c4c4;
  height: 30px;
  border-radius: 50px;
  width: 35px;
  margin: 0 10px 0 0;
}

.listhightmid span {
  font-style: normal;

  font-size: 14px;
  color: #1b1b1b;
}

.listhightmid img {
  margin: 0 0 0 20px;
  float: right;
  border-radius: 11px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.listhightmid {
  width: 100%;
}

.mapcomtopright {
  width: 100%;
  display: inline-block;
  position: relative;
}

.mapcomtopright img {
  width: 100%;
}

.mapoverlaycontent {
  background-color: RGBA(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  margin: auto;
  border-radius: 20px;
  padding: 2em;
  align-items: center;
  position: absolute;
  top: 35%;
  width: 70%;
  left: 21vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mapoverlaycontent i::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 120vh;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    #0843ba 0%,
    #0f3bbd 6%,
    #1631bb 12%,
    #1e2bbe 18%,
    #2725c1 24%,
    #3b2cc3 29%,
    #4e31c4 35%,
    #5e2dc8 41%,
    #6b29c7 47%,
    #7b24c6 53%,
    #8f20cb 59%,
    #a11cca 65%,
    #c21dcd 71%,
    #cd23bc 76%,
    #d129a1 82%,
    #d22d85 88%,
    #d4356f 94%,
    #d73c59 100%
  );
}

.mapoverlaycontent i {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.mapoverlaycontent h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1b1b1b;
  text-align: center;
}

.mapoverlaycontent p {
  font-style: normal;

  font-size: 14px;
  color: #1b1b1b;
  text-align: center;
  margin: 0 0px 0px 0px;
  display: block;
}

.mapoverlaycontent span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1b1b1b;
  text-align: center;
  display: block;
  margin: 10px 0 0 0;
}

.dropdown.procontent .dropdown-toggle::after {
  display: none;
}

.homedadshbginn h3 {
  font-weight: 600;
  color: #fff;
  font-size: 44px;
}

.homedadshbginn p {
  color: #fff;

  font-size: 20px;
}

.homedadshbginn {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homedadshbg {
  background-image: url("./assets/images/paris-front-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height:80vh;
  background-size: 100% 100%;
  border-radius: 3em !important;
}

.homedadshbginn a {
  background: #fff;
  color: #000;
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  margin: 0 auto;
  border-radius: 2em !important;
  transition: all 0.5s ease;
  padding: 15px 35px 15px;
  border: 1px solid transparent;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;
  font-weight: 600;
  font-size: 20px;

  text-decoration: none;
}

.homedadshbg.gamedashbg {
  background-image: linear-gradient(
    to right,
    #54bbfb,
    #43b3fb,
    #32aafb,
    #1fa2fb,
    #0a99fa
  );
  overflow: hidden;
  border-radius: 1em !important;
}

.gamecomingsoon {
  width: 100%;
  margin: 20px 0 0 0;
}

.gamecomingsoon h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100%;

  font-size: 44px;
}

.mapcomtop {
  position: relative;
}

/* 
.gamecomingsoon h2 span {
  background-image: linear-gradient(to left, #a1d0f0, #9ec6f2, #a1baf0, #abadec, #b89fe2);
  padding: 6px 20px;
  border-radius: 18px 0 0 18px;
  margin: 0 10px 0 0;
}
 */

.profiledash {
  display: block;
  position: relative;
  background-color: RGBA(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  margin: auto;
  border-radius: 20px;
  padding: 2em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.profiledash i::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 1030px;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 0.7s linear;
  background-image: linear-gradient(
    25deg,
    #0843ba 0%,
    #0f3bbd 6%,
    #1631bb 12%,
    #1e2bbe 18%,
    #2725c1 24%,
    #3b2cc3 29%,
    #4e31c4 35%,
    #5e2dc8 41%,
    #6b29c7 47%,
    #7b24c6 53%,
    #8f20cb 59%,
    #a11cca 65%,
    #c21dcd 71%,
    #cd23bc 76%,
    #d129a1 82%,
    #d22d85 88%,
    #d4356f 94%,
    #d73c59 100%
  );
}

.profiledash i {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.tableback input {
  width: 100%;
  margin: 0 0 10px !important;
  display: block;
  background: #fff;
  background-color: rgb(255, 255, 255);
  height: 45px;
  border: 0;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  color: #caced8;
}

.tableback select {
  width: 100%;
  appearance: auto !important;
  margin: 0 0 10px !important;
  display: block;
  background: #fff;
  background-color: rgb(255, 255, 255);
  height: 45px;
  border: 0;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  color: #caced8;
}

.tableback label {
  font-style: normal;

  font-size: 16px;
  margin: 0 0 5px 0;
  color: #000;
}

.tablebackleft h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.tablebackright span {
  font-style: normal;

  font-size: 16px;
  text-align: right;
  float: right;
}

.toplistnft img {
  width: 100%;
}

.downlistnft {
  margin: 10px 0 0 0;
}

.wavemain {
  position: relative;
  text-align: center;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  color: white;
}

.inner-header {
  height: 90vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

.metalivingright.waveleft img {
  position: initial;
}

.waveright h1 {
  color: #fff;
  font-size: 40px;
}

.waveright a {
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
  color: #fff;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 100px;
  transition: all 0.5s ease;
  padding: 15px 35px 15px;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;

  text-decoration: none;
  margin: 20px 0 0 0;
}

.waveright {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.waveright img {
  margin: 0 0 20px 0;
}

/* .technology.futmid {
  padding-bottom: 0;
} 

.realthing.nftreal.futmidinn {
  margin: 0% 0 3%;
}*/

.feedbackfut {
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 10px;
  transition: all 0.5s ease;
  padding: 15px 35px 15px;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;

  text-decoration: none;
  margin: 0px 0 0 0;
}

.waveinn {
  background-image: url("./assets/images/futbg.jpg");
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
}

.inner_wavein {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.metatesting p {
  color: #000;

  font-size: 18px;
}

.metatesting h2 {
  color: #fff;
  font-size: 40px;
  margin: 0 0 10px 0;
}

.summary-item {
  background: #fff;
  color: #000;
  padding: 15px;
  border-radius: 10px;
  height: calc(100% - 0px);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metatestingright h2 {
  color: #000;
  font-size: 36px;
  margin: 0 0 10px 0;
}

.summary {
  display: block;
  position: relative;
  width: 100%;
}

.summary-item p {
  color: #828384;

  font-size: 16px;
  text-align: left;
  padding: 0px !important;
  margin: 0px 0 0 0 !important;
}

.summary-item h5 {
  color: #000;
  font-size: 22px;
  margin: 0 0 10px 0;
}

.summary-item p {
  color: #828282;

  font-size: 16px;
  margin: 0;
  text-align: left !important;
}

.metatestingright p {
  color: #000;

  font-size: 16px;
  padding: 0 200px;
  margin: 30px 0 0 0;
  text-align: center;
}

.popup-btn {
  position: fixed;
  width: 175px;
  height: 50px;
  left: 100%;
  top: 50%;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateX(-40%);
  z-index: 99;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff !important;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  gap: 12px;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 0px 0px 10px 10px;

  font-style: normal;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.popup-btn a {
  color: #fff !important;
  text-decoration: none;
}

.metatesting {
  text-align: left;
}

.metatesting h2 span {
  --text-fill-color: transparent;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.metatesting h3 {
  display: inline-block;

  color: #fff;
  font-size: 42px;
  margin: 0 0 0px 10px;
  background: radial-gradient(
    136.03% 6755.5% at 111.63% -36.03%,
    #eb4a26 0%,
    #a417d4 45.31%,
    #0046b8 100%
  );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.recommendedmain {
  background: #ecf1f8;
  width: 100%;
  padding: 50px 0;
}

.buildbox {
  width: 100%;
  position: relative;
  display: block;
  padding: 50px 0;

  background-image: url("./assets/images/buildbg.jpg");
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.buildheading h2 {
  color: #000;
  font-size: 36px;
  margin: 0 0 40px 0;
  text-align: center;
}

.leftbuild {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
.leftbuild img {
  width: 80px;
  margin: 0 0 40px 0;
}

.leftbuild a {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff !important;

  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  gap: 12px;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  border-radius: 10px;

  font-style: normal;
  font-size: 26px;
  text-decoration: none;
  text-align: center;
}

.buildbox i {
  position: absolute;
  width: 1px;
  height: 190px;
  padding: 2px;
  background: linear-gradient(to bottom, #0744b9 0%, #9d19d3 25%, #dd4049 100%);
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leftbuild.rightbuild a {
  background: transparent;
  box-shadow: initial;
  color: #374a55 !important;
}

.buildmainn {
  position: relative;
  width: 100%;
  height: 100%;
}

.steplogmain {
  margin: 50px 0 0 0;
}

.steplogheading h2 {
  color: #000;
  font-size: 36px;
  margin: 0 0 40px 0;
  text-align: center;
}

.steplogitem {
  border-radius: 15px;
  background: #ecf1f8;
  padding: 20px;
  width: 100%;
  display: block;
  height: calc(100% - 0px);
}

.steplogitem span {
  color: #d7e6f6;

  font-size: 50px;
  margin: 0 0 5px 0;
  text-align: right;
  display: block;
}

.steplogitem h2 {
  color: #000;
  font-size: 22px;
  margin: 0 0 10px 0;
}

.steplogitem p {
  color: #838383;

  font-size: 16px;
}

.builddownload a {
  margin: 0 0 0 0;
  background-image: linear-gradient(
    to right,
    #2d39c0,
    #4d37c4,
    #6533c7,
    #7b2fc9,
    #8f28ca,
    #a11fc2,
    #b014b9,
    #bd07b0,
    #c6099e,
    #cb178e,
    #cd2680,
    #cc3474
  );
  color: #fff;

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: normal;
  text-align: center;
  outline: none;
  border-radius: 100px;
  transition: all 0.5s ease;
  padding: 15px 35px 15px;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;

  font-size: 18px;

  text-decoration: none;
  margin: 40px 0 0 0;
}

.builddownload {
  text-align: center;
}

.blueboxinn.futbluebox {
  width: 90%;
}

.blu.futblueboxinn {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
  .leftdashboard {
    position: relative !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav.banefitsmain .nav-link h2 {
    font-size: 21px;
  }

  .nav.banefitsmain li {
    margin: 0 30px 0 0;
  }

  .footballlist li {
    margin: 0 40px 0px 0;
  }

  .main-navigation ul li {
    padding: 0 10px;
  }

  .main-navigation ul li a {
    font-size: 16px;
  }

  .button-file {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .leftprofilebtninn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .callout h2 {
    font-size: 38px;
  }

  .metalivingright img {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
  }

  .nav.banefitsmain li {
    margin: 0 0px 0 0;
    width: auto;
  }

  .nav.banefitsmain .nav-link {
    padding: 0px;
    display: block;
    width: 100%;
    text-align: center;
  }

  .nav.banefitsmain .nav-link p {
    text-align: center;
  }

  .banefits-box-type-1 {
    height: 300px;
    background-size: 100% 100%;
  }

  .banefits-box-type-2 {
    height: 400px;
  }

  .nav.nav-pills.banefitsmain {
    margin: 0 0 60px 0;
    display: block;
  }

  .onebenifit {
    margin: 0 0 30px 0;
  }

  .footballlist {
    width: 100%;
    margin: 40px auto 0;
    text-align: center;
    display: inline-block;
  }

  .footballlist li {
    display: block;
    margin: 0 0px 30px 0;
    width: 50%;
    display: inline-block;
  }

  .hover.hover-2.text-white.hover-sky {
    height: 340px;
  }

  .hover.hover-2.text-white.hover-sky img {
    object-fit: cover;
  }

  .leftfootball {
    margin: 0 0px 0px 0;
  }

  .rightfootball {
    text-align: center;
  }

  .rightfootball h3 {
    text-align: center;
  }

  .gaptwo {
    margin: 25px 0 20px 0px;
  }

  .nftlist li {
    width: 48%;
    margin: 0 5px;
  }

  .realthing.nftreal .col-md-5 {
    width: 100%;
  }

  .footer-info {
    margin: 15px 0 0 0;
  }

  .realthing.nftreal .col-md-6 {
    width: 100%;
  }

  .realthingright {
    margin: 30px 0 0 0;
  }

  .realthing.nftreal .col-md-1 {
    display: none;
  }

  .realthingright h2 {
    font-size: 34px;
    font-weight: bold;
  }

  .press-releasemid {
    width: 80%;
    margin: 0px auto;
  }

  .press-releaselast {
    width: 100%;
    height: auto;
    margin: 0 0 30px 0;
  }

  .socialicon {
    padding: 0;
    margin: 50px 0 0 0;
    list-style: none;
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .socialicon li {
    position: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    flex: auto;
    margin: 10px;
    text-align: center;
  }

  .testimain i::before {
    left: 0%;
    top: 0px;
    width: 100%;
    height: 120vh;
    transform: translate(0%, 0%) rotate(0deg);
  }

  .partner-list li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0px;
    margin: 0 0 20px 0;
  }

  .partner-list i::before {
    left: 0%;
    top: 0px;
    width: 100%;
    height: 170vh;
    transform: translate(0%, 0%) rotate(0deg);
  }

  .blueboxinn {
    width: 100%;
  }

  .blueboxinn .button.button-blue {
    float: left;
    margin: 20px 0 0 0;
  }

  .main-navigation ul {
    margin: 0;
    padding: 0;
    display: block;
  }

  .main-navigation ul li {
    padding: 0 0px;
    list-style: none;
  }

  .main-navigation ul li a {
    color: #000;
  }

  .main-navigation ul {
    background: #fff;
    padding: 10px;
  }

  .main-navigation ul .button-file.btnjoin {
    padding: 11px 40px;
    display: inline-block;
  }

  .main-navigation ul .button-file {
    padding: 11px 40px;
    display: inline-block;
  }

  .header-right.btngrajoin {
    display: none;
  }

  .banefits-box.banefits-box-type-4a {
    margin: 0 0 30px 0;
  }

  .banefits-box.banefits-box-type-3d {
    margin: 0 0 30px 0;
  }

  .hidepanel {
    display: block;
  }

  .logingrayinn i::before {
    width: 180% !important;
  }

  .loginformmain {
    width: 100%;
  }

  .loginformmain {
    width: 100% !important;
  }

  #loginbtnn {
    font-size: 22px;
  }

  .realthing.nftreal .col-md-5 {
    width: 100%;
  }

  .nav-link.bottomlogout {
    position: inherit;
    margin: 0% 0 0 0 !important;
  }

  .leftprofile {
    height: auto;
    margin: 0 0 30px 0;
  }

  .code {
    margin: 30px 0 0px 0;
  }

  .code a {
    font-size: 14px;
  }

  .logingrayinnblur h2 {
    font-size: 30px;
    text-align: center;
  }

  .section-boddymodelright {
    width: 100%;
    text-align: center;
  }

  .section-boddymodelleft {
    width: 50%;
    float: none;
    margin: 0 auto;
  }

  .section-boddymodel {
    width: 100%;
    display: block;
  }

  #close_btn_registerone {
    bottom: 0;
    right: 0px;
    top: 0;
    left: inherit;
  }

  .section-boddymodelleft img {
    width: 100%;
    border-radius: 40px;
    margin: 0 0 20px 0;
  }

  .section-boddymodelright p {
    line-height: 26px;
    font-size: 16px;
  }

  .banefits-box.banefits-box-type-2.lastbanefits {
    height: auto !important;
  }

  .banefits-box.banefits-box-type-2.banefits-box-type-5a {
    height: 350px !important;
  }

  #ngrecaptcha-0 iframe {
    max-width: 100%;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    margin: 0 0 0 -40px;
  }

  #ngrecaptcha-1 iframe {
    max-width: 100%;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    margin: 0 0 0 -40px;
  }

  .buildbox i {
    left: 51%;
  }

  .leftbuild a {
    padding: 10px 15px;
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .callout h2 {
    font-size: 38px;
  }
  .callout {
    width: 90% !important;
  }
  .hover {
    border-radius: 20px;
  }

  .metaliving {
    margin: 80px 0 0;
    padding: 25px;
  }
  .homedadshbg {
    background-image: url("./assets/images/paris-front-bg.png");
    background-repeat: no-repeat;
    width: 100%;
    height:25vh;
    background-size: 100% 100%;
    border-radius: 3em !important;
  }
  .mapoverlaycontent {
    background-color:none !important;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    margin: auto;
    border-radius: 20px;
    padding: .5em;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .mapoverlaycontent i::before {
    content: "";
    position: absolute;
    left: 10%;
    top: 10%;
    width: 100%;
    height: 120vh;
    /* transform: translate(-50%, -50%) rotate(90deg); */
    background-image: none !important;
  }
  #myVideo {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  #myVideo1 {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .leftprofilebtninn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .left-mob-dashboard{
    width: 100%;
  }
  .metalivingright img {
    width: 60%;
    position: relative;
    top: 0;
    left: 25%;
    right: 40%;
  }

  .nav.banefitsmain li {
    margin: 0 0px 0 0;
    width: auto;
  }

  .nav.banefitsmain .nav-link {
    padding: 0px;
    display: block;
    width: 100%;
    text-align: center;
  }

  .nav.banefitsmain .nav-link p {
    text-align: center;
  }

  .banefits-box-type-1 {
    height: 200px;
    background-size: 100% 100%;
  }

  .nav.nav-pills.banefitsmain {
    margin: 0 0 60px 0;
    display: block;
  }

  .onebenifit {
    margin: 0 0 30px 0;
  }

  .footballlist {
    width: 100%;
    margin: 40px auto 0;
    text-align: center;
    display: inline-block;
  }

  .footballlist li {
    display: block;
    margin: 0 0px 30px 0;
    width: 50%;
    display: inline-block;
  }

  .realthingleft {
    margin: 0 0 30px 0;
  }

  .realthing {
    padding: 30px;
  }

  .hover.hover-2.text-white.hover-sky {
    height: 340px;
  }

  .hover.hover-2.text-white.hover-sky img {
    object-fit: cover;
  }

  .leftfootball {
    margin: 0 0px 0px 0;
  }

  .rightfootball {
    text-align: center;
  }

  .rightfootball h3 {
    text-align: center;
  }

  .gaptwo {
    margin: 25px 0 20px 0px;
  }

  .nftlist li {
    width: 100%;
  }

  .realthingright h2 {
    font-size: 38px;
  }

  .press-releasemid {
    width: 80%;
    margin: 0px auto;
  }

  .footer-info {
    margin: 15px 0 0 0;
  }

  .press-releaselast {
    width: 100%;
    height: auto;
    margin: 0 0 30px 0;
  }

  .socialicon {
    padding: 0;
    margin: 50px 0 0 0;
    list-style: none;
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .socialicon li {
    position: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    flex: auto;
    margin: 10px;
    text-align: center;
  }

  .testimain i::before {
    left: 0%;
    top: 0px;
    width: 100%;
    height: 120vh;
    transform: translate(0%, 0%) rotate(0deg);
  }

  .partner-list li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0px;
    margin: 0 0 20px 0;
  }

  .partner-list i::before {
    left: 0%;
    top: 0px;
    width: 100%;
    height: 170vh;
    transform: translate(0%, 0%) rotate(0deg);
  }

  .blueboxinn {
    width: 100%;
  }

  .blueboxinn .button.button-blue {
    float: left;
    margin: 0px 0 0 0;
  }

  .main-navigation ul {
    margin: 0;
    padding: 0;
    display: block;
  }

  .main-navigation ul li {
    padding: 0 0px;
    list-style: none;
  }

  .main-navigation ul li a {
    color: #000;
  }

  .main-navigation ul {
    background: #fff;
    padding: 10px;
  }

  .main-navigation ul .button-file.btnjoin {
    padding: 11px 40px;
    display: inline-block;
  }

  .main-navigation ul .button-file {
    padding: 11px 40px;
    display: inline-block;
  }

  .header-right.btngrajoin {
    display: none;
  }

  .banefits-box.banefits-box-type-4a {
    margin: 0 0 0px 0;
  }

  .banefits-box.banefits-box-type-3d {
    margin: 0 0 30px 0;
  }

  .hidepanel {
    display: block;
  }

  .logingrayinn i::before {
    width: 180% !important;
  }

  .loginformmain {
    width: 100%;
  }

  .loginformmain {
    width: 100% !important;
  }

  #loginbtnn {
    font-size: 22px;
  }

  .nav-link.bottomlogout {
    position: inherit;
    margin: 0% 0 0 0 !important;
  }

  .leftprofile {
    height: auto;
    margin: 0 0 30px 0;
  }

  .code {
    margin: 30px 0 0px 0;
  }

  .code a {
    font-size: 14px;
  }

  .section-boddymodelright {
    width: 100%;
    text-align: center;
  }

  .section-boddymodelleft {
    width: 50%;
    float: none;
    margin: 0 auto;
  }

  .section-boddymodel {
    width: 100%;
    display: block;
  }

  #close_btn_registerone {
    bottom: 0;
    right: 0px;
    top: 0;
    left: inherit;
  }

  .section-boddymodelleft img {
    width: 100%;
    border-radius: 40px;
    margin: 0 0 20px 0;
  }

  .section-boddymodelright p {
    line-height: 26px;
    font-size: 16px;
  }

  .banefits-box.banefits-box-type-2.lastbanefits {
    height: auto !important;
  }

  .banefits-box.banefits-box-type-2.banefits-box-type-5a {
    height: 350px !important;
  }

  .bluebox {
    height: 350px;
  }

  .logingrayinnblur h2 {
    font-size: 30px;
    text-align: center;
  }

  /* .callout p {
    display: none;
  } */
  .callout p br {
    display: none;
  }

  /* .hover-2-content p {
    display: none;
  } */

  .metatestingright p {
    padding: 0 0px;
  }

  .summary-item {
    height: auto;
    margin: 25px 0 0px 0;
  }

  .buildbox i {
    display: none;
  }

  .steplogitem {
    height: auto;
    margin: 0 0 40px 0;
  }

  .blu.futblueboxinn {
    width: 100%;
    display: block;
  }

  .blueboxinn h3 {
    margin: 0 0 20px 0;
    font-weight: bold;
  }
}

@media only screen and (min-width: 320px) and (max-width: 490px) {
  .wrappersub .field input {
    height: 45px;
    width: 100%;
    border-radius: 0px;
    padding: 0px 0px 0 15px;
    font-size: 16px;
  }

  .wrappersub {
    height: auto;
    width: 100%;
  }

  .wrappersub .field .btn-2 {
    position: inherit;
    top: 0;
    right: 0;
    transform: inherit;
    font-size: 16px;
    height: auto;
    width: 100%;
    border-radius: 0px;
  }

  .bluebox {
    height: 430px;
  }

  #ngrecaptcha-0 iframe {
    max-width: 100%;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    margin: 0 0 0 -40px;
  }
}

@media(max-width:900px){
  .leftprofilebtninn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
