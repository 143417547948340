.tile {
  width: 50px;
  height: 50px;
}

.tile-0 {
  background-image: url('../../../assets/images/profile_placeholder.png');
}

.tile-1 {
  background-image: url('../../../assets/images/profile_placeholder.png');
}
.profile_bannner {
  border-radius: 1em;
  background-color: white;
  border: 0.5px solid lightgray;
}
.banner_img-section {
  height: 14em;
  width: 100%;
  border-radius: 1em 1em 0 0;
}
.banner_content-section {
  border-radius: 0 0 1em 1em;
  height: auto;
}
.banner_content-section-edit {
  border-radius: 0 0 1em 1em;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_avatar {
  border-radius: 0.5em;
  border: 3px solid white;
  height: 7em;
}
.avatar_img {
  height: "100%";
  width: "100%";
  object-fit: cover;
  border-radius: 0.5em;
}
.copy_div {
  border-radius: 0.3em;
  background-color: #eef4ff;
  height: 2em;
  padding: 0 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.bloqs-box {
  background: linear-gradient(101deg, #ddeefa 4.72%, #c4ebf4 76.89%);
  border-radius: 1em;
  padding: 1em;
  position: relative;
}
.xdc-box {
  background: linear-gradient(100.23deg, #fff3f7 8.59%, #f2e9db 100%);
  border-radius: 1em;
  padding: 1em;
  position: relative;
}
.bonus-box {
  background: linear-gradient(101deg, #ddeefa 4.72%, #c4ebf4 76.89%);
  border-radius: 1em;
  padding: 1em;
}

.bg-img {
  position: absolute;
  right: 15%;
  top: 7%;
  object-fit: contain;
}
.refer_box {
  background-image: url("../../../assets/images/refer_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0.5em 1em;
  margin: 0 3em 0 0;
  height: auto;
  width: "100%";
}
.reward-boxes {
  border-radius: 0.5em;
  padding: 1em;
  background: linear-gradient(102.66deg, #78ceff -17.72%, #0295fa 80.89%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copy_refer-code {
  background-color: white;
  padding: 0.5em;
  width: "100%";
  height: 2.5em;
  border-radius: 0.5em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.social-icon-box {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em 0.6em 0.4em 0.6em;
  background: #1c83e5;
  cursor: pointer;
}
.refer-table-head {
  background: #eef4ff;
  border-radius: 0.5em;
  padding: 1em;
}
.refer-table-body {
  border-bottom: 0.5px solid lightgray;
  padding: 1em;
}

.nft_card {
  border-radius: 1em;
  background-color: white;
  border: 0.5px solid lightgray;
  padding: 1em;
}

.nft_card:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.img-zoom-animation {
  transition: transform 0.8s;
}
.img-zoom-animation:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.edit-profile-img {
  border: 1px solid lightgray;
  height: 100%;
  border-radius: 0.5em;
  padding: 1em;
  height: 18em;
}

.outer-table-wrapper {
  overflow: auto;
}
.table-referral {
  max-height: 300px;
}

#sharethis-1667470906627.st-has-labels .st-btn.st-remove-label {
  min-width: 0 !important;
  padding: 0.3em 0.5em 0.5em 0.5em !important;
}
.social-icon-box .st-btn[data-network="messenger"] {
  background-color: transparent !important;
  min-width: 0 !important;
  padding: 0.2em 0.3em 0.2em 0.3em !important;
  margin: 0 !important;
}

#sharethis-1667470906627.st-has-labels .st-btn {
  min-width: 116px;
}
#sharethis-1667470906627 .st-btn:last-child {
  margin-right: 0;
}

.download_build-btn {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 1.5em;
  padding: 0.9em 2em;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white !important;
}
.colored_text {
  color: white;
  font-weight: 700;
}

.contentofvideo{
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
}
.contentofvideo video {
  width: 100%;
  height: 100%;
  display: block;
}
.contentofvideo:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.download-build-buttons{
  flex-direction:row;
  position:relative;
  top:40%;
  bottom:40%;
}
@media (max-width: 600px) {
  .refer_box {
    margin: 0;
  }
  .outer-table-wrapper {
    overflow: auto;
  }
  .table-referral {
    min-width: 900px;
  }
  .download-build-buttons{
    flex-direction:column;
    position:relative;
    top:40%;
    bottom:40%;
  }
  .download_build-btn {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: none !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 1.5em;
    border: 1px solid lightgray;
    padding: 0.9em 2em;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white !important;
    width: 100%;
  }
}
