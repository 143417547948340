.map{
 background-color: black;
 position: relative !important;
}
.tip{
    position: absolute !important;
    color: #fff;
    font-size: medium;
    font-weight: 500; 
    z-index: 100;
    padding: 20px;
    background: black;
    margin-left: 300px;
    /* margin-top: -60px; */
    max-width: 280px;
    transition: opacity 0.2s ease, top 0.2s ease, left 0.2s ease,
    margin-left 0.2s ease;
    border-radius: 8px;
    user-select: none;
    pointer-events: none;
    text-align: left;
   }
.atlas-wrapper {
    position: absolute !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }



