.my-modal .modal-content {
  background-color: transparent !important;
  border-radius: 1em;
  border: none !important;
  outline: none !important;
}
.modal_header{
  background: linear-gradient(90deg, #6DC6FE 0%, #0295FA 100%);
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1em 1em 0 0;
}
.modal_body{
  padding:1em
}
.buybloq-input-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border: 1px solid #e4e4e4 !important;
  border-radius: .6em;
  width:100%;
}
.buybloqs-input{
  outline: none !important;
  border: none !important;
  height: 100%;
  width: 100%;
}

.disabled-link {
  pointer-events: none;
}