.technology {
  padding: 80px 0;
  display: block;
  width: 100%;
}
.buildheading h3 {
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
  margin: 0 0 40px 0;
}

.buildheading h1 {
  padding: 0;
  background: #78ceff;
  background: linear-gradient(to right, #78ceff 0%, #0295fa 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 38px;
  font-weight: bold;
}

.buildheading h2 {
  color: #000;
  font-size: 36px;
  margin: 0 0 40px 0;
  text-align: center;
}

.hover_difference {
  transition: transform 0.8s;
}
.hover_difference:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  transform-origin: 50% 50%;
}
