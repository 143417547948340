@font-face {
  font-family: "LufgaRegular";
  src: local("LufgaRegular"),
    url("./assets/fonts/lufga/LufgaRegular.ttf") format("truetype");
}
@font-face {
  font-family: "LufgaBold";
  src: local("LufgaBold"),
    url("./assets/fonts/lufga/LufgaBold.ttf") format("truetype");
}
