.modal_header{
    background: linear-gradient(90deg, #6DC6FE 0%, #0295FA 100%);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1em 1em 0 0;
}
.modal_body{
    padding:1em
}
.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #0295FA !important;
    border-radius: 10px;
    top: 0;
}
.rangeslider, .rangeslider .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
}
.rangeslider, .rangeslider .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
}
.rangeslider-horizontal {
    height: 8px !important;
    border-radius: 10px;
}
.left-swap-bal{
    background-color: #e4e4e4;
    border-radius: .5em;
    padding: 1em .5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}
.right-swap-bal{
    background-color: #e4e4e4;
    border-radius: .5em;
    padding: 1em .5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 40%;
}

@media(max-width:600px){
    .left-swap-bal{
        width: 100%;
        margin: 0 0 .5em 0;
    }
    .right-swap-bal{
        width: 100%;
    }
}